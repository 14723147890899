import {
  CountryFlagIcon48,
  getRegionForVintage,
  getWineTypeName,
  useI18N,
} from '@vivino/js-web-common';
import { ComponentSize } from '@webtypes/common';
import { BasicVintageWithBasicWine } from '@webtypes/goApi';
import cx from 'classnames';
import React from 'react';
import Anchor from 'vivino-js/atoms/Anchor/Anchor.presentational';
import { countryUrl, explorerUrl, regionUrl } from 'vivino-js/vivinoUrls';

import styles from './wineInfoLocation.scss';

const TRANSLATIONS = {
  wineTypeFromRegion: 'common.wine_type_from_region',
};

interface WineInfoLocationProps {
  vintage: BasicVintageWithBasicWine;
  size?: ComponentSize;
}

const WineInfoLocation = ({ vintage, size = ComponentSize.Base }: WineInfoLocationProps) => {
  const region = getRegionForVintage(vintage);
  const country = region?.country;
  const wineTypeId = vintage?.wine?.type_id;
  const wineType = wineTypeId && getWineTypeName(wineTypeId, 1);

  const { t } = useI18N();

  return (
    <div className={cx(styles.wineInfoLocation, styles[size])}>
      <CountryFlagIcon48
        countryCode={country?.code}
        countryName={country?.name}
        className={styles.countryFlag}
      />
      <div className={styles.regionAndCountry}>
        {size === ComponentSize.Base && (
          <>
            {region?.name}, {country?.name}
          </>
        )}

        {size === ComponentSize.Large && (
          <>
            {t(TRANSLATIONS.wineTypeFromRegion, {
              wine_type: (
                <Anchor
                  data-testid="wineType"
                  key="wineType"
                  href={explorerUrl(
                    // @ts-ignore
                    { wineTypeIds: [wineTypeId] }
                  )}
                  className={styles.link}
                >
                  {wineType}
                </Anchor>
              ),
              region: (
                <Anchor
                  data-testid="region"
                  key="region"
                  href={regionUrl({ region })}
                  className={styles.link}
                >
                  {region?.name}
                </Anchor>
              ),
              country: (
                <Anchor
                  data-testid="country"
                  key="country"
                  className={styles.link}
                  // @ts-ignore
                  href={countryUrl({ country })}
                >
                  {country?.name}
                </Anchor>
              ),
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default WineInfoLocation;
