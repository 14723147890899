import { DefaultPageProps } from '@webtypes/common';
import { BasicVintageWithBasicWine, FullWinery } from '@webtypes/goApi';
import React from 'react';
import Page from 'vivino-js/utilities/Page';

import WineryPage from '@components/WineryPage/WineryPage';

interface WineryPageProps extends DefaultPageProps {
  google_maps_api_key: string;
  winery: FullWinery;
  winery_sponsorship: any;
  vintage_prices: any;
  vintages_with_prices: BasicVintageWithBasicWine[];
  vintages_without_prices: BasicVintageWithBasicWine[];
}

export default ({
  google_maps_api_key: googleMapsApiKey,
  locale,
  vintage_prices,
  winery,
  winery_sponsorship,
  vintages_with_prices,
  vintages_without_prices,
  ...rest
}: WineryPageProps) => {
  return (
    <Page {...rest}>
      <WineryPage
        locale={locale}
        googleMapsApiKey={googleMapsApiKey}
        winery={winery}
        vintage_prices={vintage_prices}
        winery_sponsorship={winery_sponsorship}
        vintages_with_prices={vintages_with_prices}
        vintages_without_prices={vintages_without_prices}
      />
    </Page>
  );
};
