export const INSTAGRAM_URL = 'https://www.instagram.com';

// Example: https://www.instagram.com/vivino -> vivino
export const extractInstagramUsername = (value: string) => {
  if (!value) {
    return '';
  }

  // Example: @vivino -> vivino
  if (value.includes('@')) {
    return value.split('@')[1];
  }

  // Example: vivino -> vivino
  if (!value.includes('instagram.com')) {
    return value;
  }

  const username = value.split('instagram.com/')[1];

  // Example: vivino/ -> vivino
  if (username && username[username.length - 1] === '/') {
    return username.slice(0, -1);
  }

  return username;
};

export const instagramUsernameUrl = (value: string) => {
  if (!value) {
    return '';
  }

  if (value.includes('@')) {
    return `${INSTAGRAM_URL}/${value.split('@')[1]}`;
  }

  if (value.includes('http')) {
    return value;
  }

  return `${INSTAGRAM_URL}/${value}`;
};
