import { useBreakpoint } from '@context/DeviceBreakpointContext/DeviceBreakpointContext';
import { Typography, TypographyType } from '@vivino/js-react-common-ui';
import { formatRating, useI18N } from '@vivino/js-web-common';
import { ComponentDirection } from '@webtypes/common';
import { FullWinery } from '@webtypes/goApi';
import cx from 'classnames';
import React from 'react';

import { formatNumber } from '@lib/numbers';

import styles from './wineryStats.module.scss';

interface WineryStatsProps {
  winery: FullWinery;
  locale: string;
  direction?: ComponentDirection;
}

const TRANSLATIONS_PATH = 'components.shared.winery_statistics';

const TRANSLATIONS: { [key: string]: string } = {
  number_of_wines_one: `${TRANSLATIONS_PATH}.number_of_wines.one`,
  number_of_wines_other: `${TRANSLATIONS_PATH}.number_of_wines.other`,
  number_of_wines_zero: `${TRANSLATIONS_PATH}.number_of_wines.zero`,
  average_rating_one: `${TRANSLATIONS_PATH}.average_rating.one`,
  average_rating_other: `${TRANSLATIONS_PATH}.average_rating.other`,
  average_rating_zero: `${TRANSLATIONS_PATH}.average_rating.zero`,
};

const numberOfWinesText = (count: number) => {
  if (count === 0) {
    return TRANSLATIONS.number_of_wines_zero;
  } else if (count === 1) {
    return TRANSLATIONS.number_of_wines_one;
  } else {
    return TRANSLATIONS.number_of_wines_other;
  }
};

const averageRatingText = (rating: number) => {
  if (rating === 0) {
    return TRANSLATIONS.average_rating_zero;
  } else if (rating === 1) {
    return TRANSLATIONS.average_rating_one;
  } else {
    return TRANSLATIONS.average_rating_other;
  }
};

function WineryStats({
  winery,
  locale,
  direction = ComponentDirection.Vertical,
}: WineryStatsProps) {
  const { t } = useI18N();
  const { isTablet, isDesktop, isMobile } = useBreakpoint();

  const isHorizontal = direction === ComponentDirection.Horizontal && !isMobile;

  const fontStatsNumbers =
    isTablet || isDesktop ? TypographyType.LabelLarge : TypographyType.LabelMedium;

  const fontStatsText =
    isTablet || isDesktop ? TypographyType.BodyMedium : TypographyType.BodySmall;

  return (
    <div
      className={cx(styles.wineryStats, {
        [styles.horizontal]: isHorizontal,
      })}
    >
      <Typography type={fontStatsText}>
        {winery.statistics.wines_count > 0 && (
          <>
            <strong>{winery.statistics.wines_count}</strong>{' '}
          </>
        )}

        {t(numberOfWinesText(winery.statistics.wines_count))}
      </Typography>

      {isHorizontal && <Typography type={fontStatsNumbers}>&#183;</Typography>}

      <Typography type={fontStatsText}>
        {winery.statistics.ratings_average > 0 && (
          <>
            <strong>{formatRating(winery.statistics.ratings_average)}</strong>{' '}
          </>
        )}

        {t(averageRatingText(winery.statistics.ratings_average), {
          count: formatNumber({ amount: winery.statistics.ratings_count, locale }),
        })}
      </Typography>
    </div>
  );
}

export default WineryStats;
