import { useBreakpoint } from '@context/DeviceBreakpointContext/DeviceBreakpointContext';
import { extractInstagramUsername, instagramUsernameUrl } from '@helpers/instagram';
import { isPartnerWinery } from '@helpers/winery';
import {
  Accordion,
  Container,
  EmailIcon,
  Grid,
  MentionIcon,
  NewWindowIcon,
  PhoneIcon,
  Sentinel,
  Tabs,
  Typography,
  TypographyType,
  WineRegionIcon,
  WineryIcon,
} from '@vivino/js-react-common-ui';
import {
  BasicCountry,
  CountryFlagIcon48,
  getUrlSearchParams,
  trackEvent,
  useI18N,
} from '@vivino/js-web-common';
import { ComponentSize } from '@webtypes/common';
import { BasicVintageWithBasicWine, FullWinery } from '@webtypes/goApi';
import cx from 'classnames';
import React, { useEffect, useMemo } from 'react';

import GoogleMaps from '@components/GoogleMaps/GoogleMaps';
import WineCard from '@components/WineCard/WineCard';

import PartnerWineryBadge from './components/PartnerWineryBadge/PartnerWineryBadge';
import WineryStats from './components/WineryStats/WineryStats';
import WineryStatusText from './components/WineryStatusText/WineryStatusText';

import styles from './wineryPage.module.scss';

const TRANSLATIONS_PATH = 'wineries.show.new_page';
const TRANSLATIONS: { [key: string]: string } = {
  about_headline: `${TRANSLATIONS_PATH}.about.headline`,
  country: `${TRANSLATIONS_PATH}.about.country`,
  region: `${TRANSLATIONS_PATH}.about.region`,
  phone: `${TRANSLATIONS_PATH}.about.phone`,
  email: `${TRANSLATIONS_PATH}.about.email`,
  winemaker: `${TRANSLATIONS_PATH}.about.winemaker`,
  website: `${TRANSLATIONS_PATH}.about.website`,
  instagram: `${TRANSLATIONS_PATH}.about.instagram`,
  meet_the_winery_tab: `${TRANSLATIONS_PATH}.tabs.meet_the_winery.title`,
  wines_tab: `${TRANSLATIONS_PATH}.tabs.wines.title`,
  availableOnVivino: `${TRANSLATIONS_PATH}.tabs.wines.available_on_vivino`,
  moreWinesFromWinery: `${TRANSLATIONS_PATH}.tabs.wines.more_wines`,
};

interface WineryPageProps {
  locale: string;
  googleMapsApiKey: string;
  winery: FullWinery;
  winery_sponsorship: any;
  vintage_prices: any;
  vintages_with_prices: BasicVintageWithBasicWine[];
  vintages_without_prices: BasicVintageWithBasicWine[];
}

export default function WineryPage({
  locale,
  googleMapsApiKey,
  vintage_prices: vintagePrices,
  winery,
  winery_sponsorship,
  vintages_with_prices: vintagesWithPrices,
  vintages_without_prices: vintagesWithoutPrices,
}: WineryPageProps) {
  const { region } = winery;
  const country = region?.country as BasicCountry;

  const { isMobile, isDesktop } = useBreakpoint();

  const hasMeetTheWineryContent = isPartnerWinery(winery) && winery_sponsorship;
  const hasWines = vintagesWithPrices.length > 0 || vintagesWithoutPrices.length > 0;

  const isAccordianExpandedOnDefault = useMemo(() => !isMobile, []);

  const { t } = useI18N();

  function handleWineCardClick({ vintage }) {
    trackEvent({
      event: 'Winery profile - Wines - View wine',
      props: {
        winery_id: winery.id,
        winery_name: winery.name,
        vintage_id: vintage.id,
        sponsored: isPartnerWinery(winery),
      },
    });
  }

  function handleWineryInformationClick({ action }: { action: string }) {
    trackEvent({
      event: 'Winery profile - About - Action',
      props: {
        winery_id: winery.id,
        winery_name: winery.name,
        action,
      },
    });
  }

  function handlePageIntersect({ pageName }) {
    trackEvent({
      event: `Winery profile - ${pageName} - Show`,
      props: {
        winery_id: winery.id,
        winery_name: winery.name,
        sponsored: isPartnerWinery(winery),
      },
    });
  }

  useEffect(() => {
    const searchParams = getUrlSearchParams();

    trackEvent({
      event: 'Winery profile - Page - Show',
      props: {
        winery_id: winery.id,
        winery_name: winery.name,
        sponsored: isPartnerWinery(winery),
        source: searchParams.get('source'),
      },
    });
  }, []);

  const meetTheWineryTab = {
    label: t(TRANSLATIONS.meet_the_winery_tab),
    index: 0,
    tabContent: (
      <Grid container spacing={3} columns={8} justifyContent="center">
        <Grid xs={8}>
          <div className={styles.descriptionContainer}>
            <Sentinel onIntersect={() => handlePageIntersect({ pageName: 'Meet winery' })} />
            <article
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: winery_sponsorship?.content_html }}
            ></article>
          </div>
        </Grid>
      </Grid>
    ),
  };

  const winesTab = {
    label: t(TRANSLATIONS.wines_tab),
    index: 1,
    tabContent: (
      <div className={styles.wines}>
        <Sentinel onIntersect={() => handlePageIntersect({ pageName: 'Wines' })} />

        {vintagesWithPrices.length !== 0 && (
          <div>
            <Typography type={TypographyType.TitleLarge}>
              {t(TRANSLATIONS.availableOnVivino)}
            </Typography>

            <Grid container spacing={3}>
              {vintagesWithPrices.map((vintage) => (
                <Grid xs={12} sm={6} key={vintage.id}>
                  <div className={styles.cardWrapper}>
                    <WineCard
                      size={ComponentSize.Large}
                      key={vintage.id}
                      price={vintagePrices[vintage.id]}
                      vintage={vintage}
                      showOutOfStock={false}
                      onClick={() => handleWineCardClick({ vintage })}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        )}

        {vintagesWithoutPrices.length !== 0 && (
          <div>
            {vintagesWithPrices.length !== 0 && (
              <Typography type={TypographyType.TitleLarge}>
                {t(TRANSLATIONS.moreWinesFromWinery, { winery_name: winery.name })}
              </Typography>
            )}

            <Grid container spacing={3}>
              {vintagesWithoutPrices.map((vintage) => (
                <Grid xs={12} sm={6} key={vintage.id}>
                  <div className={styles.cardWrapper}>
                    <WineCard
                      size={ComponentSize.Large}
                      key={vintage.id}
                      vintage={vintage}
                      showOutOfStock={false}
                      isMasterWineCard
                      onClick={() => handleWineCardClick({ vintage })}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    ),
  };

  const tabs = [
    hasMeetTheWineryContent ? meetTheWineryTab : null,
    hasWines ? winesTab : null,
  ].filter(Boolean);

  const hasTabs = tabs.length > 0;

  const fallbackImageLocation = '/images/wineries/wineglasses.webp';

  const desktopBackgroundImageLocation =
    winery_sponsorship?.image?.variations?.large ||
    winery_sponsorship?.image?.location ||
    winery?.background_image?.variations?.large ||
    winery?.background_image?.location ||
    winery.region?.background_image?.variations?.large ||
    winery.region?.background_image?.location ||
    fallbackImageLocation;

  const mobileBackgroundImageLocation =
    winery_sponsorship?.image?.variations?.medium ||
    winery_sponsorship?.image?.location ||
    winery?.background_image?.variations?.medium ||
    winery?.background_image?.location ||
    winery.region?.background_image?.variations?.medium ||
    winery.region?.background_image?.location ||
    fallbackImageLocation;

  return (
    <div className={styles.wineryPage}>
      {!isDesktop && (
        <div
          data-testid="background-image"
          className={styles.backgroundImage}
          style={{ backgroundImage: `url(${mobileBackgroundImageLocation})`, height: 160 }}
        ></div>
      )}

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid xs={12} md={5} lg={4}>
            <aside className={styles.sidebar}>
              <div className={styles.logoContainer}>
                {winery.image ? (
                  <img
                    className={styles.logo}
                    src={winery.image?.location}
                    alt={winery.name}
                    data-testid="winery-logo"
                    width={88}
                    height={88}
                  />
                ) : (
                  <div className={styles.defaultLogo} data-testid="default-winery-logo">
                    <WineryIcon width={24} height={24} />
                  </div>
                )}

                {isPartnerWinery(winery) && (
                  <div className={styles.partnerBadge}>
                    <PartnerWineryBadge />
                  </div>
                )}
              </div>

              <WineryStatusText isPartnerWinery={isPartnerWinery(winery)} />

              <div>
                <Typography type={TypographyType.HeadlineLarge}>{winery.name}</Typography>
              </div>

              <WineryStats winery={winery} locale={locale} />

              <div className={cx(styles.accordianContainer)}>
                <Accordion
                  defaultExpanded={isAccordianExpandedOnDefault}
                  summary={
                    <Typography type={TypographyType.LabelLarge}>
                      {t(TRANSLATIONS.about_headline)}
                    </Typography>
                  }
                  details={
                    <div className={styles.wineryDetails}>
                      <ul className={styles.aboutList}>
                        {country && (
                          <li className={styles.listRow}>
                            <div className={styles.flag}>
                              <CountryFlagIcon48
                                countryCode={country.code}
                                countryName={country.name}
                                className={styles.flagIcon}
                                width={24}
                                height={24}
                              />
                            </div>
                            <Typography type={TypographyType.LabelMedium}>
                              {t(TRANSLATIONS.country)}
                            </Typography>
                            <Typography type={TypographyType.BodySmall}>
                              <a
                                href={`/wine-countries/${country.seo_name}`}
                                onClick={() => handleWineryInformationClick({ action: 'country' })}
                              >
                                {country.name}
                              </a>
                            </Typography>
                          </li>
                        )}

                        {region && (
                          <li className={styles.listRow}>
                            <WineRegionIcon width={24} height={24} />
                            <Typography type={TypographyType.LabelMedium}>
                              {t(TRANSLATIONS.region)}
                            </Typography>
                            <Typography type={TypographyType.BodySmall}>
                              <a
                                href={`/wine-regions/${region.seo_name}`}
                                onClick={() => handleWineryInformationClick({ action: 'region' })}
                              >
                                {region.name}
                              </a>
                            </Typography>
                          </li>
                        )}

                        {isPartnerWinery(winery) && (
                          <>
                            {winery.winemaker && (
                              <li className={styles.listRow}>
                                <WineryIcon width={24} height={24} />
                                <Typography type={TypographyType.LabelMedium}>
                                  {t(TRANSLATIONS.winemaker)}
                                </Typography>
                                <Typography type={TypographyType.BodySmall}>
                                  {winery.winemaker}
                                </Typography>
                              </li>
                            )}

                            {winery.website && (
                              <li className={styles.listRow}>
                                <NewWindowIcon width={24} height={24} />
                                <Typography type={TypographyType.LabelMedium}>
                                  {t(TRANSLATIONS.website)}
                                </Typography>
                                <Typography
                                  type={TypographyType.BodySmall}
                                  className={styles.truncate}
                                >
                                  <a
                                    href={winery.website}
                                    target="_blank"
                                    rel="noopener noreferrer nofollow"
                                    onClick={() =>
                                      handleWineryInformationClick({ action: 'website' })
                                    }
                                  >
                                    {winery.website}
                                  </a>
                                </Typography>
                              </li>
                            )}

                            {winery.instagram && (
                              <li className={styles.listRow}>
                                <MentionIcon width={24} height={24} />
                                <Typography type={TypographyType.LabelMedium}>
                                  {t(TRANSLATIONS.instagram)}
                                </Typography>

                                <Typography
                                  type={TypographyType.BodySmall}
                                  className={styles.truncate}
                                >
                                  <a
                                    href={instagramUsernameUrl(winery.instagram)}
                                    target="_blank"
                                    rel="noopener noreferrer nofollow"
                                    onClick={() =>
                                      handleWineryInformationClick({ action: 'instagram' })
                                    }
                                  >
                                    @{extractInstagramUsername(winery.instagram)}
                                  </a>
                                </Typography>
                              </li>
                            )}

                            {winery.email && (
                              <li className={styles.listRow}>
                                <EmailIcon width={24} height={24} />
                                <Typography type={TypographyType.LabelMedium}>
                                  {t(TRANSLATIONS.email)}
                                </Typography>
                                <Typography type={TypographyType.BodySmall}>
                                  <a
                                    href={`mailto:${winery.email}`}
                                    onClick={() =>
                                      handleWineryInformationClick({ action: 'email' })
                                    }
                                  >
                                    {winery.email}
                                  </a>
                                </Typography>
                              </li>
                            )}

                            {winery.phone && (
                              <li className={styles.listRow}>
                                <PhoneIcon width={24} height={24} />
                                <Typography type={TypographyType.LabelMedium}>
                                  {t(TRANSLATIONS.phone)}
                                </Typography>
                                <Typography type={TypographyType.BodySmall}>
                                  <a
                                    href={`tel:${winery.phone}`}
                                    onClick={() =>
                                      handleWineryInformationClick({ action: 'phone' })
                                    }
                                  >
                                    {winery.phone}
                                  </a>
                                </Typography>
                              </li>
                            )}
                          </>
                        )}
                      </ul>

                      {winery.location && (
                        <div className={styles.mapContainer} data-testid="winery-map">
                          <GoogleMaps
                            className={styles.map}
                            apiKey={googleMapsApiKey}
                            latitude={winery.location?.latitude}
                            longitude={winery.location?.longitude}
                            region={winery.region}
                            height={140}
                            language={locale}
                          />
                        </div>
                      )}
                    </div>
                  }
                />
              </div>
            </aside>
          </Grid>

          <Grid xs md lg>
            <main className={styles.contentWrapper}>
              {isDesktop && (
                <div
                  data-testid="background-image-main"
                  className={styles.backgroundImageMain}
                  style={{ backgroundImage: `url(${desktopBackgroundImageLocation})`, height: 300 }}
                ></div>
              )}
              {hasTabs && (
                <div className={styles.tabs}>
                  <Tabs tabs={tabs} value={0} />
                </div>
              )}
            </main>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
